import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  roundel: {
    width: '3.9rem',
    height: '4.32rem',
    backgroundImage: 'url("/content/assets/images/edr_roundel.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    fontFamily: 'Fresh Sans, Arial, Helvetica, sans-serif',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.fn.largerThan('md')]: {
      width: '5.12rem',
      height: '5.64rem'
    }
  },

  pointValue: {
    fontSize: '.87rem',
    lineHeight: '.87rem',
    color: '#3A4751',
    marginTop: '.75rem',
    [theme.fn.largerThan('md')]: {
      fontSize: '1.142rem',
      marginTop: '.85rem'
    }
  },

  pointValueLarge: {
    fontSize: '.75rem',
    marginTop: '.76rem',
    [theme.fn.largerThan('md')]: {
      fontSize: '1rem',
      marginTop: '.95rem'
    }
  }
}));
