import { forwardRef, useEffect } from 'react';

import { useStyles } from './ProductCardSlider.style';
import {
  type SlidesProps,
  type ProductCardSliderProps
} from './ProductCardSlider.types';

import { Slide } from '@/components/Carousel';
import { CarouselSection } from '@/components/CarouselSection';
import {
  ProductCard,
  ProductCardSkeleton
} from '@/features/ProductCard';
import { productListViewTracking } from '@/lib/tracking/product';

const Slides = ({
  isLoading,
  products,
  headerText
}: SlidesProps): React.ReactNode => {
  if (products) {
    return products.map((productProps, index) => (
      <Slide key={`${productProps.name}${index}`}>
        <ProductCard {...productProps} listName={headerText} />
      </Slide>
    ));
  }

  if (isLoading) {
    return [...Array(6)].map((_, index) => (
      <Slide key={index}>
        <ProductCardSkeleton />
      </Slide>
    ));
  }

  return null;
};

const ProductCardSlider = forwardRef<HTMLElement, ProductCardSliderProps>(({
  products,
  seeAllLink,
  title
}, ref): React.ReactElement => {
  const { classes } = useStyles();

  const breakpoints = {
    slideSize: {
      xs: '40%',
      sm: '30%',
      md: '23%',
      default: '16.6666666667%'
    },
    slideGap: {
      default: 5
    },
    slidesToScroll: {
      xs: 2,
      md: 3,
      lg: 4,
      default: 6
    }
  };

  useEffect(() => {
    if (products) {
      productListViewTracking({ products, title });
    }
  }, [products]);

  return (
    <section className={classes.productCardSliderWrapper} ref={ref}>
      <CarouselSection
        breakpoints={breakpoints}
        headerText={title}
        seeAllLink={seeAllLink}
        slidesAmount={products ? products.length : 0}
        loop
      >
        <Slides
          isLoading={!products}
          products={products}
          headerText={title}
        />
      </CarouselSection>
    </section>
  );
});

ProductCardSlider.displayName = 'ProductCardSlider';

export { ProductCardSlider };
