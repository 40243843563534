import { IconHeart, IconHeartFilled } from '@tabler/icons-react';

import { useStyles } from './WishlistButton.style';
import { type WishlistButtonProps } from './WishlistButton.types';

import { useWishlist } from '@/hooks/useWishlist/useWishlist';

export const WishlistButton = ({
  dealId,
  dealName,
  disabled
}: WishlistButtonProps): React.ReactElement => {
  const {
    addToWishlist,
    removeFromWishlist,
    isWishlisted
  } = useWishlist(dealId, dealName);

  const { classes } = useStyles({ isWishlisted });

  const handleClick = (): void => {
    if (disabled || !dealId || !dealName) {
      return;
    }

    isWishlisted ? removeFromWishlist() : addToWishlist();
  };

  return (
    <button className={classes.button} onClick={handleClick} disabled={disabled}>
      {isWishlisted ? (
        <IconHeartFilled size={16} />
      ) : (
        <IconHeart size={16} />
      )}
    </button>
  );
};
