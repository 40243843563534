import { useStyles } from './Rating.style';

import { Stars } from '@/components/Stars';

export interface RatingProps {
  count: number;
  dealUrl?: string;
  value: number;
  isPdpVariation?: boolean;
}

export const Rating = ({ count, dealUrl, value, isPdpVariation }: RatingProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <a className={classes.ratingWrapper} href={`${dealUrl}#review-section`}>
      <Stars value={value} />
      <p className={cx(classes.title, isPdpVariation && classes.underline)}>
        {isPdpVariation ? `${count} Reviews` : `(${count})`}
      </p>
    </a>
  );
};
