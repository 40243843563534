import { useStyles } from './Roundel.style';

interface RoundelProps {
  points?: string;
}

export const Roundel = ({ points }: RoundelProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <>
      {points &&
        <div className={classes.roundel}>
          <span className={cx(classes.pointValue, points.length > 4 && classes.pointValueLarge)}>{points}</span>
        </div>
      }
    </>
  );
};
