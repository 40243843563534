import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'block',
    textDecoration: 'none'
  },

  shadowWrapper: {
    transition: 'box-shadow 150ms ease-in-out',
    borderRadius: '10px',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    }
  },

  image: {
    width: '100%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    overflow: 'hidden'
  },

  textWrapper: {
    border: '1px solid #dcdcdc',
    borderTop: 'none',
    backgroundColor: 'white',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '0.625rem'
  },

  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 400,
    color: '#282828',
    margin: '0',
    marginBottom: '.1rem'
  },

  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 400,
    color: '#7b7b7b',
    margin: '0'
  }
}));
