import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },

  hideOnMobile: {
    display: 'none',

    [theme.fn.largerThan('xs')]: {
      display: 'block'
    }
  },

  hideOnDesktop: {
    display: 'none',

    [theme.fn.smallerThan('lg')]: {
      display: 'block'
    }
  },

  footerWrapper: {
    backgroundColor: '#464646',
    color: 'white',
    padding: '2rem 0',
    marginTop: '2rem',

    [theme.fn.smallerThan('xs')]: {
      paddingTop: '0'
    }
  },

  grid: {
    padding: '0',

    [theme.fn.largerThan('xs')]: {
      padding: '0 1rem',
      display: 'grid',
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))'
    },

    [theme.fn.smallerThan('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
    },

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
    },

    [theme.fn.smallerThan('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
    }
  },

  section: {
    [theme.fn.smallerThan('xs')]: {
      padding: '1rem'
    }
  },

  sectionHeader: {
    fontSize: '1.2rem',

    [theme.fn.smallerThan('xs')]: {
      margin: '0'
    }
  },

  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',

    [theme.fn.smallerThan('xs')]: {
      fontSize: '1rem'
    }
  },

  listElement: {
    padding: '0.4rem 0'
  },

  button: {
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    padding: '1rem 1.2rem',
    borderRadius: '2rem',
    backgroundColor: theme.colors.orange[5],
    color: 'white',
    textDecoration: 'none',
    marginBottom: '0.2rem',

    [theme.fn.smallerThan('xs')]: {
      display: 'block',
      margin: '1.5rem 0',
      textAlign: 'center'
    }
  },

  link: {
    color: 'white',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  plainText: {
    display: 'block'
  },

  image: {
    width: '100px',

    [theme.fn.smallerThan('xs')]: {
      width: '130px'
    }
  },

  acknowledgement: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    marginTop: '2rem',
    paddingBottom: '2.2rem',
    borderBottom: '1px solid #363636',

    [theme.fn.smallerThan('xs')]: {
      alignItems: 'flex-start'
    }
  },

  acknowledgementLine: {
    paddingBottom: '0.4rem'
  },

  copyrightLine: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '2.2rem',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      gap: '1rem'
    }
  },

  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      gap: '0'
    }
  },

  logo: {
    width: '135px'
  },

  socialMediaIconsWrapper: {
    position: 'relative',
    display: 'flex',
    gap: '1.5rem',

    [theme.fn.smallerThan('md')]: {
      justifyContent: 'center',
      gap: '0.8rem'
    }
  },

  socialMediaIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '52px',
    height: '52px',
    backgroundColor: '#6c6c6c',
    color: '#464646',
    borderRadius: '100%',

    '&:hover': {
      color: theme.colors.orange[5]
    },

    [theme.fn.smallerThan('xs')]: {
      width: '42px',
      height: '42px'
    }
  },

  accordionItem: {
    backgroundColor: 'transparent',
    fontSize: '18px',
    color: '#fff',
    borderBottom: '1px solid #171717',

    '&[data-active]': {
      backgroundColor: 'transparent'
    }
  },

  accordionControl: {
    padding: '0.5rem 1rem',
    backgroundColor: '#464646',

    '&:active': {
      borderColor: 'transparent',
      backgroundColor: '#464646'
    },

    '&:hover': {
      backgroundColor: '#383838'
    }
  },
  accordionPanel: {
    backgroundColor: '#2d2d2d'
  },

  accordionItemTitle: {
    outlineOffset: '2px'
  },

  accordionLabel: {
    fontSize: '18px',
    color: '#fff',
    fontWeight: 'bold'
  },

  accordionChevron: {
    fontSize: '18px',
    color: '#fff',
    svg: {
      width: '30px',
      height: '30px'
    }
  }
}));
