import { useStyles } from './SearchBar.style';
import { type ListHeaderProps } from './SearchBar.types';

import { useRecentlyViewed } from '@/hooks/useRecentlyViewed';
import { useRecentSearches } from '@/hooks/useRecentSearches';

const ListHeader = ({ title, handleClearAll }: ListHeaderProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <h4 className={classes.listTitle}>
      {title}
      <button
        className={classes.clearAllButton}
        onClick={handleClearAll}
        type="button"
      >
        Clear All
      </button>
    </h4>
  );
};

export const DefaultDropdown = (): React.ReactElement => {
  const { classes } = useStyles();
  const { recentSearches, clearRecentSearches } = useRecentSearches();
  const { recentlyViewed, clearRecentlyViewed } = useRecentlyViewed();

  const recentlyViewedLimit = 8;

  return (
    <div className={classes.dropdownWrapper}>
      <div className={classes.dropdown}>
        {(recentSearches && recentSearches.length > 0) && (
          <>
            <ListHeader
              handleClearAll={clearRecentSearches}
              title="Recent Searches"
            />
            <ul className={classes.recentlySearchedList}>
              {recentSearches?.map((item, i) => (
                <li key={i}>
                  <a className={classes.listLink} href={item.Url}>{item.Term}</a>
                </li>
              ))}
            </ul>
          </>
        )}
        {(recentlyViewed && recentlyViewed.length > 0) && (
          <>
            <ListHeader
              handleClearAll={clearRecentlyViewed}
              title="Recently Viewed"
            />
            <ul className={classes.recentlyViewedList}>
              {recentlyViewed.slice(0, recentlyViewedLimit).map((item, i) => (
                <li key={i}>
                  <a href={item.Url}>
                    <img src={item.S3Thumbnail} className={classes.recentlyViewedImage} />
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
