import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  card: {
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem',
    padding: '0.475rem',
    transition: 'box-shadow 150ms ease-in-out',
    zIndex: 2,

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    },

    [theme.fn.largerThan('xs')]: {
      padding: '0.625rem'
    }
  },

  imageContainer: {
    position: 'relative'
  },

  image: {
    width: '100%'
  },

  cardLink: {
    textDecoration: 'none'
  },

  banner: {
    position: 'absolute',
    top: 0,
    left: 0
  },

  roundel: {
    position: 'absolute',
    bottom: '8px',
    right: '8px'
  },

  bottomSection: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '.6rem',
    height: '1.5rem'
  },

  labelSection: {
    paddingTop: '.6rem',
    paddingBottom: '.3rem',
    height: '2.6rem'
  },

  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    height: '3.2rem',
    fontSize: '1rem',
    lineHeight: '1.6rem',
    marginBottom: '.8rem',
    color: '#444444'
  }
}));
