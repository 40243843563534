import React, { useState } from 'react';

import { useMantineTheme } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useStyles } from './MobileMegaMenu.style';

import { type MegaMenuProps } from '../MegaMenu';
import { type MenuElement } from '../MegaMenu.types';

import { PartialWrapper } from '@/components/PartialWrapper';

import './slide-transitions.css';

interface SpecialLinksSectionProps {
  isLoggedIn?: boolean;
}

const SpecialLinksSection = ({ isLoggedIn }: SpecialLinksSectionProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const currentPath = encodeURIComponent(window.location.pathname);

  return (
    <>
      <ul className={cx(classes.menuList, classes.borderTop)}>
        <li>
          <a className={cx(classes.menuElementLink, classes.link)} href="/sale/todays-specials">Today&apos;s Deals</a>
        </li>
        <li className={classes.bold}>
          <a className={cx(classes.menuElementLink, classes.link)} href="/account/trackorder">Track My Order</a>
        </li>
        <li>
          <a className={cx(classes.menuElementLink, classes.link)} href="https://help.mydeal.com.au/hc/en-us">Help & Support</a>
        </li>
      </ul>
      {isLoggedIn && (
        <a className={cx(classes.borderTop, classes.link, classes.signOutLink)} href={`/account/logoff?returnUrl=${currentPath}`}>Sign Out</a>
      )}
    </>
  );
};

const AppsSection = (): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <ul className={cx(classes.menuList, classes.borderTop)}>
      <li className={cx(classes.menuElementPadding, classes.bold)}>Find Us on</li>
      <li className={classes.badgeWrapper}>
        <a href="https://apps.apple.com/au/app/mydeal-shop-for-the-home/id1512739634">
          <img className={classes.appleAppStoreBadge} src="/content/assets/images/appleappstorebadge.svg" />
        </a>
      </li>
      <li className={classes.badgeWrapper}>
        <a href="https://play.google.com/store/apps/details?id=com.appetiser.mydeal">
          <img className={classes.googlePlayStoreBadge} src="/content/assets/images/googleplaybadge.png" />
        </a>
      </li>
    </ul>
  );
};

interface MenuPanelProps {
  menu: MenuElement;
  handleOpenClick: (menuClicked: MenuElement) => void;
  handleReturnClick: () => void;
  viewAllPosition: 'top' | 'bottom';
  isLoggedIn?: boolean;
}

const MenuPanel = ({
  menu,
  handleOpenClick,
  handleReturnClick,
  viewAllPosition,
  isLoggedIn
}: MenuPanelProps): React.ReactElement => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();

  const ViewAll = (): React.ReactElement => (
    <li>
      <a className={cx(classes.menuElement, classes.menuElementPadding, classes.bold, classes.link)} href={menu.href}>View All</a>
    </li>
  );

  return (
    <div className={classes.menuPanel}>
      <ul className={classes.menuList}>
        {(menu.label.length > 0) && (
          <button type="button" className={classes.return} onClick={handleReturnClick}>
            <IconChevronLeft className={classes.returnChevron} color={theme.colors.orange[5]} size={14} />
            {menu.label}
          </button>
        )}
        {viewAllPosition === 'top' && <ViewAll />}
        {menu.children?.map(child => (
          <li className={classes.menuElement} key={child.label}>
            {(child.children && child.children.length > 0) ? (
              <>
                <button
                  className={cx(classes.menuElementButton, classes.link, child.label.toLowerCase() === 'sale' && classes.menuElementSale)}
                  type="button"
                  onClick={() => handleOpenClick(child)}
                >
                  {child.label}
                  <IconChevronRight color={theme.colors.orange[5]} size={14} />
                </button>
              </>
            ) : (
              <a
                className={cx(classes.menuElementLink, classes.link, child.label.toLowerCase() === 'sale' && classes.menuElementSale)}
                href={child.href}
              >
                {child.label}
              </a>
            )}
          </li>
        ))}
        {viewAllPosition === 'bottom' && <ViewAll />}
      </ul>
      <AppsSection />
      <SpecialLinksSection isLoggedIn={isLoggedIn} />
    </div>
  );
};

export const MobileMegaMenu = ({
  menus,
  sales,
  handleCloseMegaMenu,
  isLoggedIn
}: MegaMenuProps): React.ReactElement => {
  const { classes } = useStyles();

  const [navigationStack, setNavigationStack] = useState<MenuElement[]>([]);

  const handleOpenClick = (menuClicked: MenuElement): void => {
    if (!menuClicked.children) {
      return;
    }

    setNavigationStack([...navigationStack, menuClicked]);
  };

  const handleReturnClick = (): void => {
    setNavigationStack(navigationStack.slice(0, navigationStack.length - 1));
  };

  return (
    <nav className={classes.megaMenu}>
      <div
        className={classes.overlay}
        onClick={handleCloseMegaMenu}
      ></div>
      <MenuPanel
        menu={{
          label: '',
          href: '/categories',
          children: [...menus, sales]
        }}
        handleOpenClick={handleOpenClick}
        handleReturnClick={handleReturnClick}
        viewAllPosition="bottom"
        isLoggedIn={isLoggedIn}
      />
      <TransitionGroup>
        {navigationStack.map((menu, i) => (menu.children && (
          <CSSTransition
            key={i}
            timeout={200}
            classNames="slide"
          >
            <MenuPanel
              menu={menu}
              handleOpenClick={handleOpenClick}
              handleReturnClick={handleReturnClick}
              viewAllPosition="top"
              isLoggedIn={isLoggedIn}
              />
          </CSSTransition>
        )))}
      </TransitionGroup>
    </nav>
  );
};

export const MobileMegaMenuWrapped = (props: MegaMenuProps): React.ReactElement => (
  <PartialWrapper>
    <MobileMegaMenu {...props} />
  </PartialWrapper>
);
