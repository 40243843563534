import { Tooltip, useMantineTheme } from '@mantine/core';

import { useStyles } from './ProductCardPrice.style';

export interface ProductCardPriceProps {
  currentPrice: number;
  discount?: string;
  oldPrice?: number;
  shouldDisplayFrom?: boolean;
  isPriceDrop?: boolean;
  isOnSale?: boolean;
}

export const ProductCardPrice = ({
  currentPrice,
  discount,
  oldPrice,
  shouldDisplayFrom,
  isPriceDrop,
  isOnSale
}: ProductCardPriceProps): React.ReactElement => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles({ oldPrice });
  const shouldDisplayWasInsteadOfRrp = isPriceDrop || isOnSale;

  const getTooltipValue = (): string | null => {
    if (isPriceDrop) {
      return '\'WAS\' price is the previous lowest price in the last 14 days';
    }
    else if (isOnSale) {
      return '\'WAS\' price is the previous lowest price recently offered on MyDeal';
    }

    return null;
  };

  return (
    <>
      <span className={classes.discount}>
        {discount && `Save ${discount}`}
      </span>
      <div className={classes.boxWrapper}>
        <div className={classes.currentPrice}>
          {shouldDisplayFrom && (
            <span className={cx(classes.fromText, classes.currentPriceIndex)}>From&nbsp;</span>
          )}
          <span className={cx(classes.dollarSign, classes.currentPriceIndex)}>
            $
          </span>
          <span className={cx(classes.currentPriceValue, classes.currentPriceIndex)}>
            {currentPrice}
          </span>
          <svg className={classes.background} viewBox="0 0 140 30" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <polygon points="0,0 140,0 120,30 0,30" fill={theme.colors.orange[5]} />
          </svg>
        </div>
        {oldPrice && (
          <Tooltip
            disabled={!shouldDisplayWasInsteadOfRrp}
            label={getTooltipValue()}
            multiline
            width={200}
            withArrow
            arrowSize={8}
            position="top-end"
            arrowPosition="side"
            transitionProps={{ transition: 'pop', duration: 150 }}
            classNames={{
              tooltip: classes.tooltip,
              arrow: classes.arrow
            }}
          >
            <div className={classes.oldPrice}>
              {shouldDisplayWasInsteadOfRrp ? 'WAS' : 'RRP'}
              <span className={`${classes.oldPriceValue} ${shouldDisplayWasInsteadOfRrp && classes.oldPriceValueStriked}`}>
                ${oldPrice}
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};
