import { useStyles } from './TileSlider.style';

import { Slide } from '@/components/Carousel';
import { CarouselSection } from '@/components/CarouselSection';
import { Tile, type TileProps } from '@/components/Tile';
import { promotionListClick } from '@/lib/tracking/promotion';

export interface TileSliderProps {
  bannerName?: string;
  title?: string;
  seeAllLink?: string;
  tiles: TileProps[];
  maximumSlidesPerView?: number;
}

export const TileSlider = ({
  bannerName,
  title,
  seeAllLink,
  tiles,
  maximumSlidesPerView = 5
}: TileSliderProps): React.ReactElement => {
  const { classes } = useStyles();

  const handleClick = (tileProps: TileProps, index: number): void => {
    promotionListClick(tileProps.url, index, tileProps.id, bannerName, tileProps.title);
  };

  return (
    <section className={classes.tileSliderWrapper}>
      <CarouselSection
        breakpoints={{
          slideSize: {
            xs: '38%',
            sm: '30%',
            md: '25%',
            default: `${100 / maximumSlidesPerView}%`
          },
          slideGap: {
            xs: 10,
            default: 20
          },
          slidesToScroll: {
            xs: 2,
            sm: 3,
            md: 4,
            default: maximumSlidesPerView ?? 5
          }
        }}
        headerText={title}
        seeAllLink={seeAllLink}
        loop
      >
        {tiles.map((props, index) => (
          <Slide className={classes.slide} key={index}>
            <Tile
              onClick={() => handleClick(props, index)}
              {...props}
            />
          </Slide>
        ))}
      </CarouselSection>
    </section>
  );
};
