import { createStyles } from '@mantine/core';

const sharedElementPadding = {
  padding: '0.75rem 1rem'
};

export const useStyles = createStyles(theme => ({
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    touchAction: 'none'
  },

  bold: {
    fontWeight: 'bold'
  },

  borderTop: {
    marginTop: '1.5rem',
    paddingTop: '0.4rem',
    borderTop: '1px solid #dcdcdc'
  },

  link: {
    color: '#444444',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  megaMenu: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    color: '#444444',
    zIndex: 999
  },

  menuPanel: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '70%',
    height: '100vh',
    backgroundColor: '#f9f8f8',
    padding: '0.5rem 0 4rem 0',
    overflow: 'auto',
    maxWidth: '380px'
  },

  menuList: {
    listStyleType: 'none',
    padding: '0',
    margin: '0'
  },

  menuElement: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  menuElementSale: {
    color: theme.colors.orange[5],
    textTransform: 'uppercase'
  },

  menuElementPadding: {
    ...sharedElementPadding
  },

  menuElementLink: {
    ...sharedElementPadding,
    flexGrow: 1,
    display: 'block'
  },

  menuElementButton: {
    ...sharedElementPadding,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    flexGrow: 1
  },

  chevron: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },

  returnChevron: {
    paddingTop: '3px',
    marginRight: '5px'
  },

  return: {
    ...sharedElementPadding,
    backgroundColor: 'transparent',
    border: 'none',
    fontWeight: 'bold',
    color: '#444444',
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  viewAll: {
    ...sharedElementPadding,
    display: 'block',
    color: '#444444',
    textDecoration: 'none'
  },

  appleAppStoreBadge: {
    marginBottom: '1rem',
    width: '120px'
  },

  googlePlayStoreBadge: {
    width: '120px'
  },

  badgeWrapper: {
    paddingLeft: '0.85rem'
  },

  signOutLink: {
    ...sharedElementPadding,
    display: 'block',
    paddingTop: '1.2rem'
  }
}));
