import { Accordion } from '@mantine/core';

import { useStyles } from './NewFooter.style';

interface WrapperProps {
  children: React.ReactNode;
  isDesktop: boolean;
  shouldAccordionOnMobile?: boolean;
}

export const Columns = ({ children, isDesktop }: WrapperProps): React.ReactNode => {
  const { classes } = useStyles();

  if (isDesktop) {
    return children;
  }

  return (
    <Accordion classNames={{
      item: classes.accordionItem,
      itemTitle: classes.accordionItemTitle,
      label: classes.accordionLabel,
      chevron: classes.accordionChevron,
      control: classes.accordionControl,
      panel: classes.accordionPanel
    }}>
      {children}
    </Accordion>
  );
};

export const Column = ({ children, isDesktop }: WrapperProps): React.ReactNode => {
  if (isDesktop) {
    return <div>{children}</div>;
  }

  return children;
};

export const Section = ({ children, isDesktop, shouldAccordionOnMobile }: WrapperProps): React.ReactElement => {
  const { classes } = useStyles();
  // using a random string because we do not care about controlling the accordion, value can be arbitrary

  if (isDesktop || !shouldAccordionOnMobile) {
    return <div className={classes.section}>{children}</div>;
  }

  return (
    <Accordion.Item value={Math.random().toString()}>
      {children}
    </Accordion.Item>
  );
};

export const Title = ({ children, isDesktop, shouldAccordionOnMobile }: WrapperProps): React.ReactElement => {
  const { classes } = useStyles();
  const title = <h3 className={classes.sectionHeader}>{children}</h3>;

  if (isDesktop || !shouldAccordionOnMobile) {
    return title;
  }

  return <Accordion.Control>{title}</Accordion.Control>;
};

export const Content = ({ children, isDesktop, shouldAccordionOnMobile }: WrapperProps): React.ReactElement => {
  const { classes } = useStyles();
  const list = <ul className={classes.list}>{children}</ul>;

  if (isDesktop || !shouldAccordionOnMobile) {
    return list;
  }

  return <Accordion.Panel>{list}</Accordion.Panel>;
};
