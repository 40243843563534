import { useStyles } from './ProductCard.style';

import { Rating } from '@/components/Rating';
import { Skeleton } from '@/components/Skeleton';
import { WishlistButton } from '@/features/WishlistButton';

export const ProductCardSkeleton = (): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <div className={classes.card}>
      <Skeleton
        className={classes.image}
        width={200}
        height={200}
      />
      <div className={classes.labelSection}>
        <Skeleton
          className={classes.title}
          width={60}
          height={14}
        />
      </div>
      <Skeleton
        className={classes.title}
        width={200}
        height={84}
      />
      <Skeleton
        width={200}
        height={40}
      />
      <div className={classes.bottomSection}>
        <WishlistButton disabled dealId={-1} dealName="Loading" />
        <Rating count={0} value={0} />
      </div>
    </div>
  );
};
