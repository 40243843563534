import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  megaMenu: {
    position: 'relative',
    display: 'flex',
    backgroundColor: '#f9f8f8',
    borderBottom: '1px solid #dcdcdc',
    transform: 'translateY(-100%)',
    transition: 'transform 400ms ease-in-out',
    maxHeight: 'calc(100vh - 200px)',
    height: '600px',
    overflow: 'hidden'
  },

  megaMenuOpen: {
    transform: 'translateY(0)'
  },

  leftSide: {
    width: '20%',
    minWidth: '250px',
    flexShrink: 0,
    borderRight: '1px solid #dcdcdc',
    padding: '0.5rem 0 0.5rem 0',
    height: '100%',
    overflowY: 'auto'
  },

  leftSideItem: {
    width: '100%',
    padding: '10px 20px',
    color: '#444444',

    '&:hover': {
      backgroundColor: 'white',
      color: theme.colors.orange[5]
    }
  },

  rightSide: {
    flexGrow: 1,
    padding: '.5rem',
    backgroundColor: 'white',
    height: '100%',
    overflowY: 'auto'
  },

  subcategories: {
    display: 'grid',
    gap: '1.5rem 0',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
  },

  parentSubcategory: {
    display: 'block',
    fontWeight: 'bold',
    padding: '.5rem',
    paddingRight: '2rem',
    marginBottom: 0,
    textDecoration: 'none',
    color: '#444444',

    '&:hover': {
      color: theme.colors.orange[5],
      backgroundColor: '#fafafa'
    }
  },

  subcategoryLink: {
    textDecoration: 'none',
    display: 'block',
    padding: '.35rem 2rem .35rem .5rem',
    color: '#444444',

    '&:hover': {
      color: theme.colors.orange[5],
      backgroundColor: '#fafafa'
    }
  },

  viewAll: {
    display: 'block',
    padding: '.35rem .5rem',
    color: '#444444',

    '&:hover': {
      color: theme.colors.orange[5],
      backgroundColor: '#fafafa'
    }
  },

  saleLabel: {
    textTransform: 'uppercase',
    color: theme.colors.orange[5]
  },

  viewAllLabel: {
    fontWeight: 'bold'
  },

  list: {
    listStyleType: 'none',
    padding: 0,
    margin: '.2rem 0 .2rem 0'
  }
}));
