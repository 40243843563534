import * as Sentry from '@sentry/react';

export const initializeSentry = (environment: string, release: string): void => {
  if (Sentry.isInitialized()) return;

  Sentry.init({
    dsn: 'https://239fcf3e98db81cd9c402af689ff9973@o4506788338794496.ingest.us.sentry.io/4506793092448256',
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.2,
    release: `mydeal-web@${release}`,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1
  });

  Sentry.setTag('RenderMethod', 'React');
};
