import { pushToGtmDatalayer } from '@/lib/utils';

interface EcommerceData {
  currencyCode: string;
  wishlist: {
    products: Array<{
      id: number;
      name: string;
      quantity: number;
    }>;
  };
}

const generateEcommerceData = (dealId: number, dealName: string): EcommerceData => {
  return {
    currencyCode: 'aud',
    wishlist: {
      products: [{
        id: dealId,
        name: dealName,
        quantity: 1
      }]
    }
  };
};

const addToWishlistDatalayer = (dealId: number, dealName: string): void => {
  pushToGtmDatalayer({
    event: 'custom.ecommerce.wishlist.add',
    group: 'wishlist',
    label: dealName,
    data: {
      type: undefined
    },
    ecommerceType: 'wishlist-add',
    ecommerce: generateEcommerceData(dealId, dealName)
  });
};

const removeFromWishlistDatalayer = (dealId: number, dealName: string): void => {
  pushToGtmDatalayer({
    event: 'custom.ecommerce.wishlist.add',
    group: 'wishlist',
    data: {
      type: 'individual'
    },
    ecommerceType: 'wishlist-remove',
    ecommerce: generateEcommerceData(dealId, dealName)
  });
};

const addToWishlistFbPixel = (dealId: number): void => {
  if (typeof fbq === 'undefined') return;

  const contentIds = [dealId];

  fbq('track', 'AddToWishlist', {
    content_type: 'product',
    content_ids: contentIds
  });
};

export const addToWishlistTracking = (dealId: number, dealName: string): void => {
  addToWishlistDatalayer(dealId, dealName);
  addToWishlistFbPixel(dealId);
};

export const removeFromWishlistTracking = (dealId: number, dealName: string): void => {
  removeFromWishlistDatalayer(dealId, dealName);
};
