import { Tooltip } from '@mantine/core';

import { useStyles } from './Label.style';

interface LabelProps {
  children?: React.ReactNode;
  isSecondary?: boolean;
  tooltipValue?: string;
}

export const Label = ({
  children,
  isSecondary,
  tooltipValue
}: LabelProps): React.ReactElement => {
  const { classes } = useStyles({ isSecondary });

  return (
    <Tooltip
      disabled={!tooltipValue || tooltipValue.length === 0}
      label={tooltipValue}
      multiline
      width={200}
      withArrow
      arrowSize={8}
      position="bottom"
      arrowPosition="center"
      transitionProps={{ transition: 'pop', duration: 300 }}
      classNames={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
    >
      <div className={classes.label}>{children}</div>
    </Tooltip>
  );
};
