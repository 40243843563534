import { useRef, useState } from 'react';

import { type ProductCardSliderLazyProps } from './ProductCardSliderLazy.types';

import { type ProductCardProps } from '@/features/ProductCard';
import { ProductCardSlider } from '@/features/ProductCardSlider';
import { useScrollThreshold } from '@/hooks/useScrollThreshold/useScrollThreshold';
import { getProductCards } from '@/services/productCardSlider';

export const ProductCardSliderLazy = ({
  apiUrl,
  ...props
}: ProductCardSliderLazyProps): React.ReactElement => {
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [products, setProducts] = useState<ProductCardProps[]>();

  const fetchData = async (): Promise<void> => {
    if (!apiUrl) return;

    try {
      const cards = await getProductCards(apiUrl);
      setProducts(cards);
      setIsLoading(false);
    }
    catch (e) {
      setHasError(true);
      console.error(`Could not fetch products from ${apiUrl}`);
    }
  };

  useScrollThreshold(ref, fetchData, 600);

  if (hasError || (!products && !isLoading) || products?.length === 0) {
    return <></>;
  }

  return (
    <ProductCardSlider
      products={products}
      ref={ref}
      {...props}
    />
  );
};
