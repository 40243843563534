import { useContext, useState } from 'react';

import { useCookies } from 'react-cookie';

import { WishlistContext } from '@/components/PartialWrapper';
import { addToWishlistTracking, removeFromWishlistTracking } from '@/lib/tracking/wishlist';
import { addToWishlistService, removeFromWishlistService } from '@/services/wishlist';

interface UseWishlist {
  count: number;
  addToWishlist: () => void;
  removeFromWishlist: () => void;
  isWishlisted: boolean;
}

export const useWishlist = (dealId?: number, dealName?: string): UseWishlist => {
  const context = useContext(WishlistContext);

  if (context === undefined) {
    throw new Error('useWishlist must be used in WishlistProvider');
  }

  const {
    count,
    incrementWishlist,
    decrementWishlist
  } = context;
  const [cookies, setCookie] = useCookies(['wish']);
  const [isWishlisted, setIsWishlisted] = useState(false);

  const addToWishlist = async (): Promise<void> => {
    if (!dealId || !dealName) return;

    let cookie = cookies.wish;
    if (!cookie) {
      cookie = {};
    }

    setIsWishlisted(true);
    incrementWishlist();

    cookie[dealId] = '1';
    setCookie('wish', cookie);
    await addToWishlistService(dealId);
    addToWishlistTracking(dealId, dealName);
  };

  const removeFromWishlist = async (): Promise<void> => {
    if (!dealId || !dealName) return;

    let cookie = cookies.wish;
    if (!cookie) {
      cookie = {};
    }

    setIsWishlisted(false);
    decrementWishlist();

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete cookie[dealId];
    setCookie('wish', cookie);
    await removeFromWishlistService(dealId);
    removeFromWishlistTracking(dealId, dealName);
  };

  return {
    count,
    addToWishlist,
    removeFromWishlist,
    isWishlisted
  };
};
