import { type MutableRefObject } from 'react';

import { useStyles } from './Header.style';

import { IconPurchase, IconUser, IconSignOut } from '@/components/Icons';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

interface DropDownLink {
  icon: React.ReactElement;
  text: string;
  href: string;
  nofollow?: boolean;
}

const DropDownItem = ({ link }: { link: DropDownLink }): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <li className={classes.dropDownElement}>
      <a href={link.href} className={classes.dropDownLink} rel={link.nofollow ? 'nofollow' : ''}>
        <span className={classes.dropDownIcon}>{link.icon}</span>
        {link.text}
      </a>
    </li>
  );
};

interface UserDropDownProps {
  isUserDropDownOpen: boolean;
  handleTriggerUserDropDown: (newState: boolean) => void;
  firstName?: string;
  dropdownRef: MutableRefObject<null>;
}

export const UserDropDown = ({
  isUserDropDownOpen,
  handleTriggerUserDropDown,
  firstName,
  dropdownRef
}: UserDropDownProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  const handleCloseDropDown = (): void => {
    if (isUserDropDownOpen) {
      handleTriggerUserDropDown(false);
    }
  };

  useOnClickOutside(dropdownRef, handleCloseDropDown);

  const links: DropDownLink[] = [
    {
      icon: <IconUser color="#B1B1B1" size={16} />,
      text: 'My Details',
      href: '/myaccount/details'
    },
    {
      icon: <IconPurchase color="#B1B1B1" size={16} />,
      text: 'My Purchases',
      href: '/myaccount/fetchvouchers'
    },
    {
      icon: <IconSignOut color="#B1B1B1" size={16} />,
      text: 'Sign Out',
      href: '/account/logoff?returnUrl=%2F',
      nofollow: true
    }
  ];

  return (
    <ul
      className={cx(
        classes.dropDown,
        isUserDropDownOpen && classes.dropDownActive
      )}
    >
      <li className={cx(classes.dropDownElement, classes.dropDownElementNoHover)}>
        <span className={classes.dropDownText}>G&apos;day {firstName}!</span>
      </li>
      {links.map((link, i) => (
        <DropDownItem
          key={i}
          link={link}
        />
      ))}
    </ul>
  );
};
