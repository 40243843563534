import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  mobile: {
    [theme.fn.largerThan('xs')]: {
      display: 'none'
    }
  },

  desktop: {
    display: 'none',

    [theme.fn.largerThan('xs')]: {
      display: 'block'
    }
  },

  ratingWrapper: {
    color: '#444444',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: theme.colors.orange[5],
      textDecoration: 'underline'
    }
  },

  underline: {
    textDecoration: 'underline',
    color: '#747474',
    marginLeft: '.5rem'
  },

  title: {
    fontSize: '0.7rem',
    paddingTop: '0.15rem',
    margin: 0,

    [theme.fn.largerThan('xs')]: {
      fontSize: '1rem',
      paddingTop: 0
    }
  },

  label: {
    '&[data-read-only]': {
      cursor: 'inherit'
    }
  }
}));
