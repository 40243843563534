import { useState } from 'react';

import { useCookies } from 'react-cookie';

import {
  type RecentSearchesHook,
  type RecentSearch
} from './useRecentSearches.types';

interface Cookies {
  _recentlySearchedTerms?: any;
}

const getRecentSearches = (cookies: Cookies): RecentSearch[] => {
  return cookies._recentlySearchedTerms || [];
};

export const useRecentSearches = (): RecentSearchesHook => {
  const [cookies, setCookie] = useCookies(['_recentlySearchedTerms']);
  const [recentSearches, setRecentSearches] = useState(getRecentSearches(cookies as Cookies));

  const clearRecentSearches = (): void => {
    setRecentSearches([]);
    setCookie('_recentlySearchedTerms', '');
  };

  return {
    recentSearches,
    clearRecentSearches
  };
};
