import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './MobileHeader.style';

import { type HeaderProps } from '../Header';
import { IconsRow } from '../IconsRow';
import { UserDropDown } from '../UserDropDown';

import { IconBurger } from '@/components/Icons';
import { PartialWrapper, ScrollContext } from '@/components/PartialWrapper';
import { MegaMenu } from '@/features/MegaMenu';
import { SearchBar } from '@/features/SearchBar';

interface SearchBarWrapperProps {
  isFocused: boolean;
  handleFocus: (isFocused: boolean, forceClose?: boolean) => void;
  shouldDisplaySearchBar: boolean;
  setShouldDisplaySearchBar: (newState: boolean) => void;
}

const SearchBarWrapper = ({
  isFocused,
  handleFocus,
  shouldDisplaySearchBar,
  setShouldDisplaySearchBar
}: SearchBarWrapperProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const [scrollY, setScrollY] = useState(0);
  const scrollPosition = useContext(ScrollContext);

  useEffect(() => {
    if (isFocused) {
      return;
    }

    if (shouldDisplaySearchBar && scrollY < scrollPosition.y && scrollPosition.y > 100) {
      setShouldDisplaySearchBar(false);
    }
    else if (!shouldDisplaySearchBar && scrollY > scrollPosition.y) {
      setShouldDisplaySearchBar(true);
    }

    setScrollY(window.scrollY);
  }, [scrollPosition]);

  return (
    <div className={cx(
      classes.searchWrapper,
      shouldDisplaySearchBar && classes.searchWrapperActive
    )}>
      <SearchBar
        isFocused={isFocused}
        handleFocus={handleFocus}
      />
    </div>
  );
};

export const MobileHeader = ({
  cartCount,
  isLoggedIn,
  userInitials,
  firstName,
  megaMenuProps
}: HeaderProps): React.ReactElement => {
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [shouldDisplaySearchBar, setShouldDisplaySearchBar] = useState(true);

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const dropdownRef = useRef(null);

  const handleOpenMegaMenu = (): void => setIsMegaMenuOpen(true);
  const handleCloseMegaMenu = (): void => setIsMegaMenuOpen(false);

  const handleTriggerUserDropDown = (newState: boolean): void => setIsUserDropDownOpen(newState);

  const handleSearchFocus = (newState: boolean, forceClose?: boolean): void => {
    if (isTablet) {
      setIsSearchFocused(newState);
    }
    else if (newState) {
      setIsSearchFocused(true);
      setShouldDisplaySearchBar(true);
    }
    else if (forceClose) {
      setIsSearchFocused(false);
    }
  };

  return (
    <div className={classes.headerWrapper}>
      {(isTablet && isSearchFocused) && <div className={classes.searchOverlay}></div>}
      <div className={classes.dropDownWrapper}>
        <div className={classes.header}>
          <div className={classes.flexWrapper}>
            <button aria-label="Shop by category" className={classes.burgerWrapper} onClick={handleOpenMegaMenu}>
              <IconBurger size={18} color="#B1B1B1" />
            </button>
            <a href="/" className={classes.logoWrapper}>
              <img
                alt="Online Deals by MyDeal"
                className={classes.logo}
                src="/content/assets/images/mydeal_weblogo.svg"
                width="171"
                height="38"
              />
            </a>
          </div>
          {isTablet && (
            <SearchBar
              className={classes.searchBarTablet}
              isFocused={isSearchFocused}
              handleFocus={handleSearchFocus}
            />
          )}
          <div className={classes.flexWrapperNoGap}>
            <IconsRow
              isSearchFocused={isSearchFocused}
              isLoggedIn={isLoggedIn}
              isMobile
              userInitials={userInitials}
              firstName={firstName}
              isUserDropDownOpen={isUserDropDownOpen}
              handleSearchFocus={handleSearchFocus}
              handleTriggerUserDropDown={handleTriggerUserDropDown}
              cartCount={cartCount}
            />
          </div>
        </div>
        {!isTablet && (
          <UserDropDown
            firstName={firstName}
            isUserDropDownOpen={isUserDropDownOpen}
            handleTriggerUserDropDown={handleTriggerUserDropDown}
            dropdownRef={dropdownRef}
          />
        )}
      </div>
      {!isTablet && (
        <SearchBarWrapper
          isFocused={isSearchFocused}
          handleFocus={handleSearchFocus}
          shouldDisplaySearchBar={shouldDisplaySearchBar}
          setShouldDisplaySearchBar={setShouldDisplaySearchBar}
        />
      )}
      {isMegaMenuOpen && (
        <MegaMenu
          handleCloseMegaMenu={handleCloseMegaMenu}
          isLoggedIn={isLoggedIn}
          {...megaMenuProps}
        />
      )}
    </div>
  );
};

export const MobileHeaderWrapped = (props: HeaderProps): React.ReactElement => (
  <PartialWrapper>
    <MobileHeader {...props} />
  </PartialWrapper>
);
