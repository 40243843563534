import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({

  bannerWrapper: {
    backgroundColor: '#ffc8cd',
    marginBottom: '1.4rem',
    [theme.fn.largerThan('sm')]: {
      marginBottom: '1.625rem'
    }
  },

  bannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.fn.largerThan('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },

  copy: {
    padding: '2.25rem 0 0 0',
    [theme.fn.largerThan('md')]: {
      width: '50%',
      padding: '2.25rem 3rem 2.25rem'
    }
  },

  header: {
    marginBottom: '1rem',
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    marginTop: '0',
    fontFamily: '"Agrandir", Arial',
    color: theme.colors.orange[5],
    [theme.fn.largerThan('md')]: {
      fontSize: '3rem',
      lineHeight: '3.875rem'
    }
  },

  subHeader: {
    fontFamily: '"Agrandir", Arial',
    color: '#282828',
    fontSize: '1.125rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    [theme.fn.largerThan('md')]: {
      fontSize: '1.75rem',
      lineHeight: '1.875rem'
    }
  },

  tncLink: {
    color: '#444',
    '&a': {
      color: '#444'
    }
  },

  linkNShop: {
    width: 'fit-content',
    paddingLeft: '2.75rem',
    paddingRight: '2.75rem'
  },

  photo: {
    paddingTop: '1.25rem',
    paddingLeft: '1.25rem',
    marginTop: 'auto',
    [theme.fn.largerThan('md')]: {
      width: '33%'
    }
  }
}));
