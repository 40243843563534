import { createStyles } from '@mantine/core';

interface StyleProps {
  isSecondary?: boolean;
}

export const useStyles = createStyles((theme, { isSecondary }: StyleProps) => ({
  label: {
    display: 'inline-block',
    borderRadius: '4px',
    border: `2px solid ${theme.colors.orange[5]}`,
    padding: '2px',
    fontWeight: isSecondary ? 'normal' : 'bold',
    fontSize: '0.75rem',
    color: isSecondary ? 'white' : theme.colors.orange[5],
    backgroundColor: isSecondary ? theme.colors.orange[5] : 'white',
    textTransform: isSecondary ? 'uppercase' : 'none',

    [theme.fn.largerThan('xs')]: {
      fontSize: '0.85rem'
    }
  },

  tooltip: {
    color: '#444',
    backgroundColor: '#ffffff',
    border: '1px solid #dcdcdc'
  },

  arrow: {
    border: '1px solid #dcdcdc'
  }
}));
