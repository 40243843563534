import { useRef } from 'react';

import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './Header.style';
import { UserDropDown } from './UserDropDown';

import {
  IconCart,
  IconEdrLogo,
  IconEdrLogoSmall,
  IconHeart,
  IconUser,
  IconX
} from '@/components/Icons';
import { useWishlist } from '@/hooks/useWishlist';

interface UserIconProps {
  isLoggedIn: boolean;
  isUserDropDownOpen: boolean;
  isMobile?: boolean;
  userInitials?: string;
  onTriggerUserDropDown: (newState: boolean) => void;
}

const UserIcon = ({
  isLoggedIn,
  isUserDropDownOpen,
  isMobile,
  userInitials,
  onTriggerUserDropDown
}: UserIconProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  if (!isLoggedIn) {
    return (
      <a
        className={cx(classes.icon, classes.iconDropDown)}
        href="/account/logon?returnUrl=%2F"
        title="Account Menu"
      >
        <IconUser size={20} />
      </a>
    );
  }

  return (
    <button
      className={cx(
        classes.icon,
        classes.iconDropDown,
        isUserDropDownOpen && classes.iconDropDownActive
      )}
      onClick={() => onTriggerUserDropDown(!isUserDropDownOpen)}
      title="Account Menu"
    >
      <IconUser size={20} />
      {!isMobile && (
        <span className={classes.initials}>
          {userInitials}
        </span>
      )}
    </button>
  );
};

interface IconsRowProps {
  isSearchFocused?: boolean;
  isLoggedIn: boolean;
  isMobile?: boolean;
  userInitials?: string;
  firstName?: string;
  isUserDropDownOpen: boolean;
  handleTriggerUserDropDown: (newState: boolean) => void;
  handleSearchFocus?: (isFocused: boolean, forceClose?: boolean) => void;
  cartCount?: number;
}

export const IconsRow = ({
  isSearchFocused,
  isLoggedIn,
  isMobile,
  userInitials,
  firstName,
  isUserDropDownOpen,
  handleTriggerUserDropDown,
  handleSearchFocus,
  cartCount
}: IconsRowProps): React.ReactElement => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const dropdownRef = useRef(null);
  const { count: wishlistCount } = useWishlist();

  if ((isMobile && !isTablet) && isSearchFocused) {
    return (
      <button
        className={classes.icon}
        onClick={handleSearchFocus && (() => handleSearchFocus(false, true))}
        type="button"
      >
        <IconX />
      </button>
    );
  }

  return (
    <div className={classes.iconsRowWrapper}>
      <a
        className={classes.icon}
        href={isLoggedIn ? '/myaccount/everydayrewards' : '/everyday-rewards'}
        title="Everyday Rewards"
      >
        {isMobile ? <IconEdrLogoSmall /> : <IconEdrLogo />}
      </a>
      <div className={classes.dropDownWrapper} ref={dropdownRef}>
        <UserIcon
          isLoggedIn={isLoggedIn}
          isUserDropDownOpen={isUserDropDownOpen}
          isMobile={isMobile}
          userInitials={userInitials}
          onTriggerUserDropDown={handleTriggerUserDropDown}
        />
        {isTablet && (
          <UserDropDown
            firstName={firstName}
            isUserDropDownOpen={isUserDropDownOpen}
            handleTriggerUserDropDown={handleTriggerUserDropDown}
            dropdownRef={dropdownRef}
          />
        )}
      </div>
      <a href="/wishlist" className={classes.icon} title="Wishlist">
        <IconHeart size={20} />
        {wishlistCount > 0 && (
          <span className={classes.bubble}>{wishlistCount}</span>
        )}
      </a>
      <a href="/cart" className={classes.icon} title="Your Cart">
        <IconCart size={20} />
        {!!(cartCount && cartCount > 0) && (
          <span className={classes.bubble}>{cartCount}</span>
        )}
      </a>
    </div>
  );
};
