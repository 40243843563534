import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  section: {
    margin: '2rem 0'
  },

  headerTitle: {
    color: '#444444',
    marginBottom: '1rem',
    marginTop: 0,
    paddingLeft: 0,
    fontWeight: 'normal',
    fontSize: '1.5rem',
    fontFamily: '"Agrandir", Arial'
  }
}));
