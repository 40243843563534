import { Helmet } from 'react-helmet';

import { initializeSentry } from './sentry';

import { type Config } from '@/types/config';

interface TrackingHeadProps {
  config: Config;
}

export const TrackingHead = ({ config }: TrackingHeadProps): React.ReactElement => {
  const {
    ENVIRONMENT,
    RELEASE_NUMBER,
    GTM_ENABLED,
    GTM_ID,
    FACEBOOK_PIXEL_ENABLED,
    FACEBOOK_PIXEL_ID,
    PUSH_ENGAGE_ID,
    TOPSORT_API_KEY,
    TOPSORT_ENABLED,
    SFMC_ENABLED
  } = config;

  initializeSentry(ENVIRONMENT, RELEASE_NUMBER);

  return (
    <Helmet>
      {/* Google Tag Manager */}
      {GTM_ENABLED && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`}
        </script>
      )}
      {/* End Google Tag Manager */}

      {/* Facebook Pixel */}
      {FACEBOOK_PIXEL_ENABLED && (
        <script>
          {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FACEBOOK_PIXEL_ID}',{ em: '', country: 'AU' });
          fbq('track', 'PageView');`}
        </script>
      )}
      {FACEBOOK_PIXEL_ENABLED && (
        <noscript>
          {`<img height="1" width="1" style="display:none;" src="https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1" />`}
        </noscript>
      )}
      {/* End Facebook Pixel */}

      {/* SalesForce */}
      {SFMC_ENABLED && (
        <script type="text/javascript" src="https://110005440.collect.igodigital.com/collect.js"></script>
      )}
      {/* End SalesForce */}

      {/* Push Engage Notifications */}
      <script>
        {`window._peq = window._peq || [];
          window._peq.push(["init"]);`}
      </script>
      <script src={`https://clientcdn.pushengage.com/core/${PUSH_ENGAGE_ID}.js`} async></script>
      {/* End Push Engage Notifications */}

      {/* TrustPilot Widget */}
      <script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      {/* End TrustPilot Widget */}

      {/* TopSort */}
      {TOPSORT_ENABLED && (
        <script>
          {`window.TS = {
              token: "${TOPSORT_API_KEY}",
              url: "https://api.topsort.com"
            };`}
        </script>
      )}
      {/* End TopSort */}
    </Helmet>
  );
};
