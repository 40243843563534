import { CarouselSection } from '@/components/CarouselSection';
import { Container } from '@/components/Container';
import { SimpleBanner } from '@/components/SimpleBanner';
import { EDRHeroBanner } from '@/features/EDRHeroBanner';
import { FeaturedBrands } from '@/features/FeaturedBrands';
import { HeroBanner } from '@/features/HeroBanner';
import { NewsletterSection } from '@/features/NewsletterSection';
import { OfferCardGrid } from '@/features/OfferCardGrid';
import { ProductCardSlider } from '@/features/ProductCardSlider';
import { ProductCardSliderLazy } from '@/features/ProductCardSliderLazy';
import { QuickLinks } from '@/features/QuickLinks';
import { TileSlider } from '@/features/TileSlider';
import { TrustpilotCarousel } from '@/features/TrustpilotCarousel';
import type {
  ComponentProps,
  ComponentMetadata,
  ComponentMap,
  ComponentType
} from '@/types/cms';

export const ComponentsMap: ComponentMap<ComponentType> = {
  quickLinks: {
    component: QuickLinks
  },
  heroBanner: {
    component: HeroBanner,
    containerSize: 'lg',
    noFullWidthPadding: true
  },
  edrHeroBanner: {
    component: EDRHeroBanner
  },
  featuredBrands: {
    component: FeaturedBrands,
    containerSize: 'md'
  },
  simpleBanner: {
    component: SimpleBanner,
    containerSize: 'lg'
  },
  carouselSection: {
    component: CarouselSection,
    containerSize: 'md'
  },
  trustpilotCarousel: {
    component: TrustpilotCarousel,
    containerSize: 'md'
  },
  offerCardGrid: {
    component: OfferCardGrid,
    containerSize: 'md'
  },
  productCardSlider: {
    component: ProductCardSlider,
    containerSize: 'md'
  },
  productCardSliderLazy: {
    component: ProductCardSliderLazy,
    containerSize: 'md'
  },
  tileSlider: {
    component: TileSlider,
    containerSize: 'md'
  },
  newsletterSection: {
    component: NewsletterSection,
    containerSize: 'lg'
  }
};

export interface SectionProps {
  bannerName?: string;
  name: ComponentType;
  sectionProps?: ComponentProps[ComponentType];
}

export const Section = ({ bannerName, name, sectionProps }: SectionProps): React.ReactElement => {
  const { component, containerSize, noFullWidthPadding } = ComponentsMap[name];

  if (sectionProps !== undefined) {
    const ComponentWithProps = component as (props: ComponentProps[typeof name]) => React.ReactElement;

    return (
      <Container size={containerSize} noFullWidthPadding={noFullWidthPadding}>
        <ComponentWithProps
          bannerName={bannerName}
          {...sectionProps}
        />
      </Container>
    );
  }

  const ComponentWithoutProps = component as () => React.ReactElement;

  return (
    <Container size={containerSize}>
      <ComponentWithoutProps />
    </Container>
  );
};

interface DynamicSectionsProps {
  sectionsData: Array<ComponentMetadata<keyof ComponentProps>>;
}

export const DynamicSections = ({ sectionsData }: DynamicSectionsProps): React.ReactNode => (
  sectionsData.map((section, i) => (
    <Section
      bannerName={section.bannerName}
      key={i}
      name={section.name}
      sectionProps={section.props}
    />
  ))
);
