import { useMemo } from 'react';

import { useStyles } from './SearchBar.style';
import {
  type Suggestion,
  type AutocompleteDropdownProps,
  type BoldTextProps,
  type SuggestionGroupProps
} from './SearchBar.types';

const BoldedText = ({ searchTerm, suggestionName }: BoldTextProps): React.ReactElement => {
  const { classes } = useStyles();

  if (!suggestionName.toLowerCase().includes(searchTerm.toLowerCase())) {
    return <>{suggestionName}</>;
  }

  return (
    <>
      {suggestionName.slice(0, searchTerm.length)}
      <strong className={classes.stringMatch}>
        {suggestionName.slice(searchTerm.length)}
      </strong>
    </>
  );
};

const SuggestionGroup = ({ title, suggestions, searchTerm }: SuggestionGroupProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <>
      {title && (
        <li className={classes.listTitle}>
          {title}
        </li>
      )}
      {suggestions.map((item, i) => (
        <li key={i}>
          <a
            className={cx(classes.listLink, classes.listLinkWithPadding)}
            href={`/search?q=${encodeURIComponent(item.SearchTerm)}`}
          >
            <BoldedText suggestionName={item.Label} searchTerm={searchTerm} />
          </a>
        </li>
      ))}
    </>
  );
};

export const AutocompleteDropdown = ({
  searchTerm,
  suggestions
}: AutocompleteDropdownProps): React.ReactElement => {
  const { classes } = useStyles();

  const generateSuggestionGroup = (suggestions: Suggestion[], suggestionType: number): Suggestion[] => {
    return suggestions.filter(e => e.SuggestionType === suggestionType);
  };

  const regularSuggestions = useMemo(() => generateSuggestionGroup(suggestions, 0), [suggestions]);
  const categorySuggestions = useMemo(() => generateSuggestionGroup(suggestions, 1), [suggestions]);
  const brandSuggestions = useMemo(() => generateSuggestionGroup(suggestions, 2), [suggestions]);

  return (
    <div className={classes.dropdownWrapper}>
      <ul className={classes.autocompleteDropdown}>
        <SuggestionGroup
          suggestions={regularSuggestions}
          searchTerm={searchTerm}
        />
        {categorySuggestions.length > 0 && (
          <SuggestionGroup
            title="Category Suggestions"
            suggestions={categorySuggestions}
            searchTerm={searchTerm}
          />
        )}
        {brandSuggestions.length > 0 && (
          <SuggestionGroup
            title="Brand Suggestions"
            suggestions={brandSuggestions}
            searchTerm={searchTerm}
          />
        )}
      </ul>
    </div>
  );
};
