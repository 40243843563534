import { createStyles } from '@mantine/core';

interface StyleProps {
  oldPrice?: number;
}

export const useStyles = createStyles((theme, { oldPrice }: StyleProps) => ({
  discount: {
    display: 'block',
    height: '1.5rem',
    color: theme.colors.orange[5],
    fontWeight: 600,
    fontSize: '0.85rem',
    lineHeight: '1.6rem',
    verticalAlign: 'bottom'
  },

  boxWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '40px',
    backgroundColor: oldPrice ? '#dcdcdc' : 'white',
    zIndex: 2
  },

  currentPrice: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '72%',
    color: 'white',
    fontWeight: 600,
    marginRight: '-8px',
    paddingRight: '5%',

    [theme.fn.largerThan('xs')]: {
      width: '75%',
      fontSize: '1rem',
      paddingRight: '9%'
    }
  },

  currentPriceIndex: {
    zIndex: 2
  },

  background: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  },

  fromText: {
    fontSize: '0.8rem',
    marginRight: '4px',
    fontWeight: 'bold'
  },

  dollarSign: {
    fontSize: '0.85rem',
    marginBottom: '0.15rem',

    [theme.fn.largerThan('xs')]: {
      marginBottom: '0.2rem'
    }
  },

  currentPriceValue: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginLeft: '2px',

    [theme.fn.largerThan('xs')]: {
      fontSize: '1.285rem'
    }
  },

  oldPrice: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#444444',
    fontSize: '0.715rem',
    flexGrow: 1
  },

  oldPriceValue: {
    fontWeight: 600
  },

  oldPriceValueStriked: {
    textDecoration: 'line-through'
  },

  tooltip: {
    color: '#444',
    backgroundColor: '#ffffff',
    border: '1px solid #dcdcdc',
    zIndex: 99
  },

  arrow: {
    border: '1px solid #dcdcdc'
  }
}));
