import { pushToGtmDatalayer } from '@/lib/utils';

export const searchStart = (): void => {
  pushToGtmDatalayer({
    event: 'custom.search.start',
    group: 'site search',
    category: 'search',
    search: {
      type: ''
    }
  });
};
