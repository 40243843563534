import { type MantineTheme, createStyles } from '@mantine/core';

import { type BannerType } from './ProductCardBanner';

interface ColorScheme {
  backgroundColor: string;
  color: string;
}

const generateColorScheme = (theme: MantineTheme, bannerType: BannerType): ColorScheme => {
  switch (bannerType) {
    case 'new':
      return {
        backgroundColor: 'white',
        color: theme.colors.orange[5]
      };
    case 'price-drop':
      return {
        backgroundColor: '#207732',
        color: '#CEF47D'
      };
    case 'limited-time-deal':
      return {
        backgroundColor: theme.colors.orange[5],
        color: 'white'
      };
    case 'sale':
      return {
        backgroundColor: theme.colors.orange[5],
        color: 'white'
      };
    case 'coupon':
      return {
        backgroundColor: '#CEF47D',
        color: '#207732'
      };
    default:
      return {
        backgroundColor: 'white',
        color: theme.colors.orange[5]
      };
  }
};

export const useStyles = createStyles((theme, { bannerType }: { bannerType: BannerType }) => ({
  banner: {
    ...generateColorScheme(theme, bannerType),
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '3px 4px 2px 5px',
    fontWeight: 600,
    fontSize: '0.75rem',
    borderTopLeftRadius: '0.5rem',

    [theme.fn.largerThan('xs')]: {
      fontSize: '0.875rem'
    }
  }
}));
