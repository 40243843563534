import { useStyles } from './ProductCardBanner.style';

import { IconCoupon } from '@/components/Icons';

export type BannerType = 'new' | 'price-drop' | 'sale' | 'limited-time-deal' | 'coupon';

export interface ProductCardBannerProps {
  bannerType: BannerType;
  couponDollarAmount?: number;
  couponPercentage?: number;
}

export const ProductCardBanner = ({
  bannerType,
  couponDollarAmount,
  couponPercentage
}: ProductCardBannerProps): React.ReactElement => {
  const { classes } = useStyles({ bannerType });

  const couponText = (() => {
    if (couponDollarAmount) return `$${couponDollarAmount} Coupon`;
    if (couponPercentage) return `${couponPercentage}% Coupon`;

    return 'Coupon';
  })();

  const bannerText = ((): string => {
    switch (bannerType) {
      case 'new':
        return 'New';
      case 'price-drop':
        return 'Price Drop';
      case 'sale':
        return 'Sale';
      case 'limited-time-deal':
        return 'Limited Time Deal';
      case 'coupon':
        return couponText;
      default:
        return '';
    }
  })();

  const icon = bannerType === 'coupon' && <IconCoupon color="#207732" />;

  return (
    <div className={classes.banner}>
      {icon}
      {bannerText}
    </div>
  );
};
