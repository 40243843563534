import { createStyles } from '@mantine/core';

const verticalPadding = '0.6rem';

export const useStyles = createStyles(theme => ({
  headerWrapper: {
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },

  searchOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.4)'
  },

  dropDownWrapper: {
    position: 'relative',
    zIndex: 1,

    [theme.fn.largerThan('sm')]: {
      zIndex: 'auto'
    }
  },

  header: {
    position: 'relative',
    borderBottom: '1px solid #dcdcdc',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    backgroundColor: 'white',

    [theme.fn.largerThan('sm')]: {
      height: '80px'
    }
  },

  burgerWrapper: {
    position: 'relative',
    right: '0.5rem',
    padding: `${verticalPadding} 0.5rem`,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },

  flexWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  searchBarTablet: {
    flexGrow: 1,
    padding: '0 1.5rem'
  },

  flexWrapperNoGap: {
    display: 'flex',
    alignItems: 'stretch',
    alignSelf: 'stretch'
  },

  logoWrapper: {
    padding: `${verticalPadding} 0`
  },

  logo: {
    maxWidth: '100px'
  },

  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: `${verticalPadding} 0.6rem`,
    color: '#b1b1b1',
    fill: '#b1b1b1'
  },

  searchWrapper: {
    padding: '0.625rem',
    transformOrigin: 'top center',
    transform: 'scaleY(0)',
    opacity: 0,
    transition: 'opacity 130ms ease-in-out, transform 130ms ease-in-out',
    pointerEvents: 'none',
    backgroundColor: 'white'
  },

  searchWrapperActive: {
    opacity: 1,
    transform: 'scaleY(100%)',
    pointerEvents: 'auto',
    transition: 'opacity 130ms ease-in-out, transform 130ms ease-in-out'
  }
}));
