import { type Config } from '@/types/config';

interface TrackingBodyEndProps {
  config: Config;
}

export const TrackingBodyEnd = ({ config }: TrackingBodyEndProps): React.ReactElement => {
  const {
    PUSH_ENGAGE_ENABLED,
    PUSH_ENGAGE_ID
  } = config;

  return (
    <>
      {/* Push Engage */}
      {PUSH_ENGAGE_ENABLED && (
        <script>
          {`(function(w, d) {
            w.PushEngage = w.PushEngage || [];
            w._peq = w._peq || [];
            PushEngage.push(['init', {
                appId: '${PUSH_ENGAGE_ID}'
            }]);
          
            var e = d.createElement('script');
          
            e.src = 'https://clientcdn.pushengage.com/sdks/pushengage-web-sdk.js';
            e.async = true;
            e.type = 'text/javascript';
            d.head.appendChild(e);
          })(window, document);`}
        </script>
      )}
      {/* End Push Engage */}
    </>
  );
};
