import { useStyles } from './OfferCardGrid.style';

import { OfferCard, type OfferCardProps } from '@/components/OfferCard';
import { promotionListClick } from '@/lib/tracking/promotion';

export interface OfferCardGridProps {
  bannerName?: string;
  offerCards: OfferCardProps[];
}

export const OfferCardGrid = ({
  bannerName,
  offerCards
}: OfferCardGridProps): React.ReactElement => {
  const { classes } = useStyles();

  const handleClick = (offerCardProps: OfferCardProps, index: number): void => {
    promotionListClick(offerCardProps.url, index % 3, offerCardProps.id, bannerName, offerCardProps.title);
  };

  return (
    <section className={classes.offerCardGrid}>
      {offerCards.map((props, index) => (
        <OfferCard
          key={index}
          onClick={() => handleClick(props, index)}
          {...props}
        />
      ))}
    </section>
  );
};
