import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  headerWrapper: {
    position: 'sticky',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: 99
  },

  dropDownWrapper: {
    height: '100%',

    [theme.fn.largerThan('sm')]: {
      position: 'relative'
    }
  },

  iconDropDown: {
    backgroundColor: 'transparent',
    border: 'none',
    height: '100%',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  iconDropDownActive: {
    boxShadow: `inset 0 -5px 0 ${theme.colors.orange[5]}`
  },

  dropDown: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    transformOrigin: 'bottom center',
    transform: 'translateY(0)',
    backgroundColor: 'white',
    borderBottom: '1px solid #dcdcdc',
    borderTop: '1px solid #dcdcdc',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    minWidth: '180px',
    width: '100%',
    zIndex: -1,
    opacity: 0,
    transition: 'transform 400ms, opacity 10ms 400ms',
    pointerEvents: 'none',

    [theme.fn.largerThan('sm')]: {
      left: '-20px',
      width: 'auto',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
      border: '1px solid #dcdcdc'
    }
  },

  dropDownActive: {
    opacity: 1,
    transform: 'translateY(100%)',
    transition: 'transform 300ms',
    pointerEvents: 'auto'
  },

  dropDownIcon: {
    position: 'relative',
    top: '3px',
    display: 'inline-block',
    color: '#b1b1b1',
    width: '1.3rem',
    marginRight: '0.5rem',
    textAlign: 'center'
  },

  dropDownLink: {
    padding: '1rem',
    display: 'block',
    color: '#282828',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  dropDownText: {
    padding: '1rem',
    display: 'block',
    color: '#282828'
  },

  dropDownElement: {
    borderBottom: '1px solid #dcdcdc',

    '&:last-child': {
      borderBottom: 'none'
    },

    '&:hover': {
      backgroundColor: '#f9f8f8'
    }
  },

  dropDownElementNoHover: {
    '&:hover': {
      backgroundColor: 'initial'
    }
  },

  iconsRowWrapper: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',

    [theme.fn.largerThan('xs')]: {
      gap: '0.5rem'
    },

    [theme.fn.largerThan('sm')]: {
      gap: '0.8rem'
    }
  },

  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0.715rem',
    color: '#b1b1b1',
    fill: '#b1b1b1',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.orange[5],
      fill: theme.colors.orange[5]
    },

    [theme.fn.largerThan('xs')]: {
      padding: '1rem'
    }
  },

  bubble: {
    position: 'absolute',
    top: '5px',
    right: '-3px',
    width: '1.2rem',
    height: '1.2rem',
    lineHeight: '1.2rem',
    verticalAlign: 'middle',
    backgroundColor: theme.colors.orange[5],
    color: 'white',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '0.675rem',

    [theme.fn.largerThan('xs')]: {
      right: '3px'
    },

    [theme.fn.largerThan('sm')]: {
      top: '5px'
    }
  },

  initials: {
    marginLeft: '0.5rem',
    color: '#636366'
  }
}));
