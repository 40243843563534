import { createStyles } from '@mantine/core';

interface StyleProps {
  hasTitle: boolean;
}

export const useStyles = createStyles((theme, { hasTitle }: StyleProps) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    borderRadius: '15px',
    transition: 'box-shadow 150ms ease-in-out',
    border: hasTitle ? '1px solid #dcdcdc' : 'none',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    }
  },

  image: {
    borderRadius: hasTitle ? '15px 15px 0 0' : '15px',
    width: '100%'
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    lineHeight: '1.3rem',
    color: '#444',
    width: '100%',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 400,
    padding: '1rem',
    borderRadius: '0 0 15px 15px',
    flexGrow: 1
  }
}));
