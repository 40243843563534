import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandInstagram,
  IconBrandPinterest
} from '@tabler/icons-react';

import { FooterSection } from './FooterSection';
import { useStyles } from './NewFooter.style';
import { type ColumnType } from './NewFooter.types';
import {
  Columns,
  Column
} from './ResponsiveWrappers';

import { Container } from '@/components/Container';
import { IconFacebook } from '@/components/Icons';
import { Image } from '@/components/Image';
import { socialIconClick } from '@/lib/tracking/socials';

export interface FooterProps {
  columns: ColumnType[];
}

export interface SocialMediaIconProps {
  href: string;
  name: string;
}

const SocialMediaIcon = ({ href, name }: SocialMediaIconProps): React.ReactElement => {
  const { classes } = useStyles();

  const iconMap = {
    facebook: <IconFacebook size={18} />,
    pinterest: <IconBrandPinterest />,
    instagram: <IconBrandInstagram />
  };

  const icon = iconMap[name] || <></>;

  const handleClick = (): void => {
    socialIconClick(href);
  };

  return (
    <a
      aria-label={name}
      className={classes.socialMediaIcon}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {icon}
    </a>
  );
};

export const Footer = ({ columns }: FooterProps): React.ReactElement => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);
  const { classes, cx } = useStyles();

  const year = new Date().getFullYear();

  return (
    <footer className={classes.footerWrapper}>
      <Container className={classes.grid} size="md">
        <Columns isDesktop={isDesktop}>
          {columns.map((column, i) => (
            <Column
              isDesktop={isDesktop}
              key={i}
            >
              {column.sections.map((section, j) => (
                <FooterSection
                  isDesktop={isDesktop}
                  key={j}
                  section={section}
                />
              ))}
            </Column>
          ))}
        </Columns>
      </Container>
      <Container className={classes.acknowledgement} size="lg">
        <Image
          alt="Acknowledgement of country logo"
          src="/content/assets/images/acknowledgement-of-country.svg"
          width={50}
          height={50}
        />
        <ul className={classes.list}>
          <li className={classes.acknowledgementLine}>
            We acknowledge the Traditional Owners and Custodians of Country throughout Australia. We pay our respect to all First Nations peoples and acknowledge Elders past and present.
          </li>
          <li className={classes.acknowledgementLine}>
            <a
              className={cx(classes.link, classes.bold)}
              href="https://www.woolworthsgroup.com.au/au/en/reconciliation/reconciliation-at-woolworths-group.html"
              target="_blank"
              rel="noreferrer"
            >
              Read more about our commitment to reconciliation
            </a>
          </li>
        </ul>
      </Container>
      <Container className={classes.copyrightLine} size="lg">
        <div className={classes.logoWrapper}>
          <a href="/">
            <Image
              alt="Online Deals by MyDeal"
              className={classes.logo}
              src="/content/assets/images/logo_footer.png"
              width={252}
              height={56}
            />
          </a>
          <p>Copyright {year} MyDeal.com.au - © All rights reserved</p>
        </div>
        <div className={classes.socialMediaIconsWrapper}>
          <SocialMediaIcon href="https://www.facebook.com/MyDealAustralia" name="facebook" />
          <SocialMediaIcon href="https://www.pinterest.com.au/MyDeal_Australia/" name="pinterest" />
          <SocialMediaIcon href="https://www.instagram.com/mydealaustralia/" name="instagram" />
        </div>
      </Container>
    </footer>
  );
};
