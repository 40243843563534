import { useEffect } from 'react';

import { TrackingBodyEnd } from './TrackingBodyEnd';
import { TrackingBodyStart } from './TrackingBodyStart';
import { TrackingHead } from './TrackingHead';

import {
  getQueryParameter,
  pushToGtmDatalayer
} from '@/lib/utils';
import { type Config } from '@/types/config';
import { type GtmDatalayer } from '@/types/gtmDatalayer';
import { type UserDetails } from '@/types/userDetails';

interface TrackingProps {
  children: React.ReactNode;
  config: Config;
  etmcDatalayer?: Array<Record<string, any>>;
  gtmDatalayer?: GtmDatalayer;
  userDetails?: UserDetails;
}

export const Tracking = ({
  children,
  config,
  etmcDatalayer,
  gtmDatalayer,
  userDetails
}: TrackingProps): React.ReactNode => {
  const disabledTrackers = config.ENVIRONMENT === 'local';

  if (disabledTrackers) {
    return children;
  }

  useEffect(() => {
    pushToGtmDatalayer(gtmDatalayer);

    const uuid = getQueryParameter('uuid');
    uuid && pushToGtmDatalayer({ uuid });

    if (userDetails && userDetails.edr.dollarBalance > 0) {
      pushToGtmDatalayer({
        edrAmount: userDetails.edr.dollarBalance
      });
    }
  }, []);

  return (
    <>
      <TrackingHead config={config} />
      <TrackingBodyStart
        config={config}
        etmcDatalayer={etmcDatalayer}
      />
      {children}
      <TrackingBodyEnd config={config} />
    </>
  );
};
