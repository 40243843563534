import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },

  primary: {
    color: theme.colors.orange[5]
  },

  uppercase: {
    textTransform: 'uppercase'
  }
}));
