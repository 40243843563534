import { type Config } from '@/types/config';

interface TrackingBodyStartProps {
  config: Config;
  etmcDatalayer?: Array<Record<string, any>>;
}

export const TrackingBodyStart = ({
  config,
  etmcDatalayer
}: TrackingBodyStartProps): React.ReactElement => {
  const {
    GTM_ENABLED,
    SFMC_ENABLED
  } = config;

  return (
    <>
      {/* Google Tag Manager */}
      {GTM_ENABLED && (
        <>
          <span style={{ display: 'none' }}>gtm enabled</span>
          <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRJMHP"
              height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
          </noscript>
        </>
      )}
      {/* End Google Tag Manager */}

      {/* SalesForce DataLayer */}
      {(SFMC_ENABLED && etmcDatalayer) && (
        <script dangerouslySetInnerHTML={{
          __html: etmcDatalayer.map(element => `_etmc.push(${JSON.stringify(element)});`).join('\n')
        }} />
      )}
      {/* End SalesForce DataLayer */}
    </>
  );
};
