import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  offerCardGrid: {
    margin: '2rem 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '1.8rem',

    [theme.fn.largerThan('xs')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },

    [theme.fn.largerThan('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },

    [theme.fn.largerThan('lg')]: {
      gap: '2.7rem'
    }
  }
}));
