import { useStyles } from './NewFooter.style';
import { type ColumnElement, type ColumnSection } from './NewFooter.types';
import { Content, Section, Title } from './ResponsiveWrappers';

interface FooterSectionProps {
  isDesktop: boolean;
  section: ColumnSection;
}

const Row = ({ element }: { element: ColumnElement }): React.ReactElement => {
  const { classes, cx } = useStyles();

  const mediaqueries = [
    element.hideOnDesktop && classes.hideOnDesktop,
    element.hideOnMobile && classes.hideOnMobile
  ];

  if (element.imageSrc) {
    const image = <img
      className={cx(
        classes.image,
        ...mediaqueries
      )}
      loading="lazy"
      src={element.imageSrc}
      alt={element.alt}
    />;

    if (element.href) {
      return (
        <a className={classes.link} href={element.href}>
          {image}
        </a>
      );
    }

    return image;
  }
  else if (element.href) {
    return (
      <a className={cx(
        element.isButton ? classes.button : classes.link,
        ...mediaqueries
      )} href={element.href}>
        {element.label}
      </a>
    );
  }

  return (
    <span className={cx(
      classes.plainText,
      ...mediaqueries
    )}>
      {element.label}
    </span>
  );
};

export const FooterSection = ({ isDesktop, section }: FooterSectionProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <Section
      isDesktop={isDesktop}
      shouldAccordionOnMobile={section.shouldAccordionOnMobile}
    >
      <>
        <Title
          isDesktop={isDesktop}
          shouldAccordionOnMobile={section.shouldAccordionOnMobile}
        >
          {section.title}
        </Title>
        <Content
          isDesktop={isDesktop}
          shouldAccordionOnMobile={section.shouldAccordionOnMobile}
        >
          {section.elements.map((element, k) => (
            <li className={classes.listElement} key={k}>
              <Row element={element} />
            </li>
          ))}
        </Content>
      </>
    </Section>
  );
};
