import { DesktopHeader } from './Desktop/DesktopHeader';
import { useStyles } from './Header.style';
import { MobileHeader } from './Mobile/MobileHeader';

import { type MegaMenuProps } from '@/features/MegaMenu/MegaMenu';

export interface HeaderProps {
  cartCount?: number;
  wishlistCount?: number;
  isLoggedIn: boolean;
  userInitials?: string;
  firstName?: string;
  megaMenuProps: MegaMenuProps;
}

export const Header = (props: HeaderProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <header className={classes.headerWrapper}>
      <DesktopHeader {...props} />
      <MobileHeader {...props} />
    </header>
  );
};
