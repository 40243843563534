import { Group, List, useMantineTheme } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import { useStyles } from './MainCategoryItem.style';

import { type MenuElement } from '@/features/MegaMenu/MegaMenu.types';

export interface MainCategoryItemProps {
  handleMainCategoryHovered: (children?: MenuElement[]) => void;
  isBold?: boolean;
  isUppercase?: boolean;
  isPrimaryColor?: boolean;
  hideChevron?: boolean;
  menuElement: MenuElement;
}

export const MainCategoryItem = ({
  handleMainCategoryHovered,
  isBold,
  isUppercase,
  isPrimaryColor,
  hideChevron,
  menuElement
}: MainCategoryItemProps): React.ReactElement => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const textClasses = `${isBold && classes.bold} ${isUppercase && classes.uppercase} ${isPrimaryColor && classes.primary}`;

  return (
    <a href={menuElement.href} key={menuElement.label}>
      <List.Item
        onMouseEnter={() => handleMainCategoryHovered(menuElement.children)}
      >
        <Group className={textClasses} position="apart">
          <span>{menuElement.label}</span>
          {!hideChevron && (
            <IconChevronRight color={theme.colors.orange[5]} size={14} />
          )}
        </Group>
      </List.Item>
    </a>
  );
};
