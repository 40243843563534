import { useEffect, useRef } from 'react';

import { useStyles } from './TrustpilotCarousel.style';

interface TrustpilotCarouselProps {
  businessId: string;
}

export const TrustpilotCarousel = ({
  businessId
}: TrustpilotCarouselProps): React.ReactElement => {
  const { classes } = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <section className={classes.section}>
      <h2 className={classes.headerTitle}>What our customers have to say</h2>
      <div className="trustpilot">
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-AU"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id={businessId}
          data-style-height="225px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
        >
          <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </div>
    </section>
  );
};
