import { useStyles } from './Stars.style';

import {
  IconHalfStar,
  IconStar
} from '@/components/Icons';

export interface StarsProps {
  value: number;
}

export const Stars = ({ value }: StarsProps): React.ReactNode => {
  const { classes } = useStyles();
  const starsAmount = 5;

  return [...Array(starsAmount)].map((e, i) => {
    if (i < value && i === Math.floor(value)) {
      return <IconHalfStar className={classes.star} color="#FBC72E" key={i} />;
    }

    return <IconStar className={classes.star} color={i < value ? '#FBC72E' : '#C7C7C7'} key={i} />;
  });
};
