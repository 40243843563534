import { pushToGtmDatalayer } from '@/lib/utils';

export const socialIconClick = (linkUrl: string): void => {
  const url = new URL(linkUrl);
  let domain = url.hostname;

  if (domain.startsWith('www.')) {
    domain = domain.substring(4);
  }

  pushToGtmDatalayer({
    event: 'click',
    eventModel: {
      link_id: '',
      link_classes: 'social-icon',
      link_url: linkUrl,
      link_domain: domain,
      outbound: true,
      event_callback: 'Function'
    },
    eventCallback: 'function'
  });
};
