import { useStyles } from './Tile.style';

import { Image } from '@/components/Image';

export interface TileProps {
  id?: number;
  url: string;
  imageUrl: string;
  alt: string;
  title?: string;
  onClick?: () => void;
}

export const Tile = ({ url, imageUrl, alt, title, onClick }: TileProps): React.ReactElement => {
  const { classes } = useStyles({ hasTitle: !!title });

  return (
    <a
      href={url}
      className={classes.tile}
      onClick={onClick}
    >
      <Image
        alt={alt}
        className={classes.image}
        src={imageUrl}
        width={225}
        height={219}
      />
      {title && (
        <div className={classes.title}>
          {title}
        </div>
      )}
    </a>
  );
};
