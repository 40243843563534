import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { DesktopMegaMenu } from './Desktop/DesktopMegaMenu';
import { type MenuElement } from './MegaMenu.types';
import { MobileMegaMenu } from './Mobile/MobileMegaMenu';

export interface MegaMenuProps {
  menus: MenuElement[];
  sales: MenuElement;
  handleCloseMegaMenu?: () => void;
  forceDesktop?: boolean;
  isMegaMenuOpen?: boolean;
  handleTriggerMegaMenu?: (newState: boolean) => void;
  isLoggedIn?: boolean;
}

export const MegaMenu = ({ forceDesktop, ...props }: MegaMenuProps): React.ReactElement => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  return (
    <>
      {(forceDesktop || isDesktop) ? (
        <DesktopMegaMenu {...props} />
      ) : (
        <MobileMegaMenu {...props} />
      )}
    </>
  );
};
