import {
  useEffect,
  useRef,
  useState
} from 'react';

export const useScrollThreshold = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  offset?: number
): boolean => {
  const [hasReachedThreshold, setHasReachedThreshold] = useState(false);
  const throttleTimeout = useRef<number | null>(null);

  const unbindScroll = (): void => window.removeEventListener('scroll', throttledScroll);

  const handleScroll = (): void => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const scrollOffset = offset || 0;

    if (rect.top <= window.innerHeight + scrollOffset) {
      setHasReachedThreshold(true);
      callback();
      unbindScroll();
    }
  };

  const throttledScroll = (): void => {
    if (throttleTimeout.current === null) {
      throttleTimeout.current = window.setTimeout(() => {
        handleScroll();
        throttleTimeout.current = null;
      }, 200);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    handleScroll();

    return () => {
      unbindScroll();

      if (throttleTimeout.current !== null) {
        clearTimeout(throttleTimeout.current);
      }
    };
  }, []);

  return hasReachedThreshold;
};
