import { useState } from 'react';

import {
  type RecentView,
  type RecentlyViewedHook
} from './useRecentlyViewed.types';

export const useRecentlyViewed = (): RecentlyViewedHook => {
  const [recentlyViewed, setRecentlyViewed] = useState(JSON.parse(localStorage.getItem('_recentlyViewedDeals') ?? '[]') as RecentView[]);

  const clearRecentlyViewed = (): void => {
    setRecentlyViewed([]);
    localStorage.removeItem('_recentlyViewedDeals');
  };

  return {
    recentlyViewed,
    clearRecentlyViewed
  };
};
