import { useStyles } from './EDRHeroBanner.style';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Image } from '@/components/Image';

export interface EDRHeroBannerProps {
  header: string;
  subHeader?: string;
  tncCopy?: string;
  tncLink?: string;
  subCopy?: string;
  imageUrl: string;
}

export const EDRHeroBanner = ({ header, subHeader, tncCopy, tncLink, subCopy, imageUrl }: EDRHeroBannerProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <section className={classes.bannerWrapper}>
      <Container size='lg' className={classes.bannerContainer}>
        <div className={classes.copy}>
          <h1 className={classes.header}>{header}</h1>
          <h2 className={classes.subHeader}>{subHeader}</h2>
          <p className={classes.tncLink}>
            {tncCopy && <a target="_blank" className={classes.tncLink} href={tncLink} rel="noreferrer">{tncCopy}</a>} { subCopy}
          </p>
          <Button className={classes.linkNShop} url='/myaccount/everydayrewards'>Link & Shop</Button>
        </div>
        <Image className={classes.photo} disableLazyLoad src={imageUrl} width={412} height={335}/>
      </Container>
    </section>
  );
};
