import { useEffect, useRef, useState } from 'react';

import { List } from '@mantine/core';

import { useStyles } from './DesktopMegaMenu.style';
import { MainCategoryItem } from './MainCategoryItem';

import { type MegaMenuProps } from '../MegaMenu';
import { type MenuElement } from '../MegaMenu.types';

import { PartialWrapper } from '@/components/PartialWrapper';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

export const DesktopMegaMenu = ({
  isMegaMenuOpen,
  menus,
  sales,
  handleTriggerMegaMenu
}: MegaMenuProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  const ref = useRef(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState(menus[0].children);
  const [isRendered, setIsRendered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleMainCategoryHovered = (children?: MenuElement[]): void => {
    if (children) {
      setSelectedSubcategories(children);
    }
  };

  const handleTransitionEnd = (): void => {
    if (!isMegaMenuOpen) {
      setIsRendered(false);
    }
  };

  const handleCloseMegaMenu = (): void => {
    if (isMegaMenuOpen && handleTriggerMegaMenu) {
      handleTriggerMegaMenu(false);
    }
  };

  useOnClickOutside(ref, handleCloseMegaMenu);

  useEffect(() => {
    if (isMegaMenuOpen) {
      setIsRendered(isMegaMenuOpen);
      setTimeout(() => setIsAnimating(isMegaMenuOpen), 100);
    }
    else {
      setIsAnimating(false);
    }
  }, [isMegaMenuOpen]);

  if (!isRendered) {
    return <></>;
  }

  return (
    <div
      className={cx(classes.megaMenu, isAnimating && classes.megaMenuOpen)}
      onTransitionEnd={handleTransitionEnd}
      ref={ref}
    >
      <div className={classes.leftSide}>
        <List classNames={{ root: classes.list, itemWrapper: classes.leftSideItem }}>
          {menus.map(mainCategoryItem => (
            <MainCategoryItem
              key={mainCategoryItem.label}
              menuElement={mainCategoryItem}
              handleMainCategoryHovered={handleMainCategoryHovered}
            />
          ))}
          <MainCategoryItem
            menuElement={sales}
            isUppercase
            isPrimaryColor
            handleMainCategoryHovered={handleMainCategoryHovered}
          />
          <MainCategoryItem
            menuElement={{
              label: 'View All',
              href: '/categories'
            }}
            isBold
            handleMainCategoryHovered={handleMainCategoryHovered}
            hideChevron
          />
        </List>
      </div>

      <div className={classes.rightSide}>
        <div className={classes.subcategories}>
          {selectedSubcategories?.map(subcategory => (
            <div key={subcategory.label}>
              <a className={classes.parentSubcategory} href={subcategory.href}>
                {subcategory.label}
              </a>

              {(subcategory.children && subcategory.children.length > 0) && (
                <>
                  <ul className={classes.list}>
                    {subcategory.children?.map(subcategoryItem => (
                      <li key={subcategoryItem.label}>
                        <a href={subcategoryItem.href} className={classes.subcategoryLink}>
                          {subcategoryItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <a className={classes.viewAll} href={subcategory.href}>
                    View All
                  </a>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const DesktopMegaMenuWrapped = (props: MegaMenuProps): React.ReactElement => (
  <PartialWrapper>
    <DesktopMegaMenu {...props} />
  </PartialWrapper>
);
