import { useEffect, useState } from 'react';

import { AutocompleteDropdown } from './AutocompleteDropdown';
import { DefaultDropdown } from './DefaultDropdown';
import { useStyles } from './SearchBar.style';
import {
  type SearchBarProps,
  type Suggestion
} from './SearchBar.types';

import { IconSearch, IconX } from '@/components/Icons';
import { searchStart } from '@/lib/tracking/search';
import { getAutocomplete } from '@/services/autocomplete';

export const SearchBar = ({
  className,
  isFocused,
  handleFocus
}: SearchBarProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [hasSearchStarted, setHasSearchStarted] = useState(false);

  useEffect(() => {
    const debounced = setTimeout(async () => {
      if (search.length < 2) {
        setSuggestions([]);
        return;
      }

      try {
        const data = await getAutocomplete(search) as unknown as Suggestion[];

        setSuggestions(data);
      }
      catch (e) {
        console.error('Error fetching autocomplete data from API.');
      }
    }, 200);

    return () => clearTimeout(debounced);
  }, [search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const query = e.target.value;

    setSearch(query);

    if (query.length > 0 && !hasSearchStarted) {
      searchStart();
      setHasSearchStarted(true);
    }
  };

  const handleClearSearch = (): void => {
    setSearch('');
  };

  return (
    <form
      action="/search"
      className={className}
      method="GET"
      onFocus={() => handleFocus(true)}
      onBlur={() => handleFocus(false)}
    >
      <div className={classes.searchWrapper}>
        <div className={classes.inputWrapper}>
          <input
            className={cx(
              classes.input,
              isFocused && classes.inputFocused
            )}
            name="q"
            placeholder="Search millions of deals..."
            type="text"
            value={search}
            onChange={handleSearchChange}
          />
          {search.length > 0 && (
            <button
              aria-label="Search"
              className={classes.clearButton}
              type="button"
              onClick={handleClearSearch}
            >
              <IconX size={12} />
            </button>
          )}
        </div>
        <button
          className={classes.button}
          type="submit"
        >
          <IconSearch color="white" size={16} />
          <span className={classes.searchText}>Search</span>
        </button>
      </div>
      {isFocused && (
        <div onMouseDown={(e) => e.preventDefault()}>
          {suggestions.length > 0 ? <AutocompleteDropdown suggestions={suggestions} searchTerm={search} /> : <DefaultDropdown />}
        </div>
      )}
    </form>
  );
};
