import { forwardRef } from 'react';

import { useStyles } from './Skeleton.style';

interface SkeletonProps {
  className?: string;
  width: number;
  height: number;
  keepAspectRatio?: boolean;
}

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({
  className,
  width,
  height,
  keepAspectRatio
}, ref): React.ReactElement => {
  const { classes, cx } = useStyles({ width, height, keepAspectRatio });

  return (
    <div className={cx(className, classes.skeleton)} ref={ref}></div>
  );
});

Skeleton.displayName = 'Skeleton';

export {
  Skeleton,
  type SkeletonProps
};
