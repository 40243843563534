import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  bannerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'box-shadow 150ms ease-in-out',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    }
  },

  title: {
    position: 'absolute',
    fontSize: '1.2rem',
    fontWeight: 400,
    fontFamily: 'Agrandir, Arial',
    color: '#444',
    zIndex: 1,
    maxWidth: '45%',
    textAlign: 'center',

    [theme.fn.largerThan('sm')]: {
      fontSize: '1.6rem'
    },

    [theme.fn.largerThan('lg')]: {
      fontSize: '1.8rem'
    }
  },

  desktopImage: {
    width: '100%',
    display: 'none',

    [theme.fn.largerThan('xs')]: {
      display: 'block'
    }
  },

  mobileImage: {
    width: '100%',

    [theme.fn.largerThan('xs')]: {
      display: 'none'
    }
  }
}));
