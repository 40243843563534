import { createStyles, keyframes } from '@mantine/core';

interface StyleProps {
  width: number;
  height: number;
  keepAspectRatio?: boolean;
}

const shimmer = keyframes({
  from: { transform: 'translateX(-100%)' },
  to: { transform: 'translateX(90%)' }
});

export const useStyles = createStyles((theme, { width, height, keepAspectRatio }: StyleProps) => {
  const generateWidthAndHeight = (): object => {
    if (keepAspectRatio) {
      return {
        width: '100%',
        paddingTop: `${height / width * 100}%`
      };
    }

    return { width, height };
  };

  return {
    skeleton: {
      ...generateWidthAndHeight(),
      maxWidth: '100%',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#F1EFF1',

      '&:after': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform: 'translateX(-100%)',
        backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 70%)',
        animation: `${shimmer} 1200ms infinite ease-in-out`,
        content: '""'
      }
    }
  };
});
