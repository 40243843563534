import { createStyles } from '@mantine/core';

interface StyleProps {
  isWishlisted?: boolean;
}

export const useStyles = createStyles((theme, { isWishlisted }: StyleProps) => ({
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    color: isWishlisted ? theme.colors.orange[5] : '#bcbcbc',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  }
}));
