import { type OfferCardGridProps } from '@/features/OfferCardGrid';
import { type TileSliderProps } from '@/features/TileSlider';
import { pushToGtmDatalayer } from '@/lib/utils';
import { type CmsData } from '@/types/cms';

interface DataLayerPromotion {
  id?: number;
  name: string;
  listName: string;
  tileName: string;
  imageName: string;
  promotionPosition: number;
}

export const promotionListView = (cmsData: CmsData): object => {
  const dataLayerPromotions: DataLayerPromotion[] = [];
  let promotionPosition = 0;

  cmsData.forEach(promotion => {
    if (promotion.name === 'tileSlider') {
      const tileSlider = promotion.props as TileSliderProps;

      tileSlider.tiles.forEach(tile => {
        dataLayerPromotions.push({
          id: tile.id,
          name: tileSlider.title || '',
          listName: promotion.bannerName || '',
          tileName: tile.title || '',
          imageName: '',
          promotionPosition: promotionPosition++
        });
      });

      promotionPosition = 0;
    }
    else if (promotion.name === 'offerCardGrid') {
      const offerCardGrid = promotion.props as OfferCardGridProps;

      offerCardGrid.offerCards.forEach(offerCard => {
        dataLayerPromotions.push({
          id: offerCard.id,
          name: '',
          listName: promotion.bannerName || '',
          tileName: offerCard.title,
          imageName: '',
          promotionPosition: promotionPosition++
        });

        if (promotionPosition === 3) {
          promotionPosition = 0;
        }
      });

      promotionPosition = 0;
    }
  });

  return {
    event: 'custom.promotion.list.view',
    info: {},
    ecommerceType: 'promoView',
    ecommerce: {
      promoView: {
        promotions: dataLayerPromotions
      }
    }
  };
};

export const promotionListTracking = (cmsData: CmsData): void => {
  pushToGtmDatalayer(promotionListView(cmsData));
};

export const promotionListClick = (
  url: string,
  position: number,
  subBannerId?: number,
  bannerName?: string,
  name?: string
): void => {
  pushToGtmDatalayer({
    event: 'custom.promotion.list.click',
    group: bannerName || '',
    label: '',
    info: {
      url: url || ''
    },
    ecommerceType: 'promoClick',
    ecommerce: {
      promoClick: {
        promotions: [{
          id: subBannerId,
          name: name || '',
          creative: `${bannerName || ''}${name || ''}`,
          position
        }]
      }
    }
  });
};
