import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  star: {
    marginRight: '2px',
    height: '11px',

    '&:last-child': {
      marginRight: 0
    },

    [theme.fn.largerThan('xs')]: {
      height: '14px'
    }
  }
}));
