import { generateUrlWithQueryParams } from '@/lib/utils';

export const addToWishlistService = async (dealId: number): Promise<Response> => {
  const url = generateUrlWithQueryParams('/wishlist/add', {
    dealid: dealId.toString()
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

  return await response.json();
};

export const removeFromWishlistService = async (dealId: number): Promise<Response | Error> => {
  const url = generateUrlWithQueryParams('/wishlist/remove', {
    dealId: dealId.toString()
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

  return await response.json();
};
