import { useStyles } from './OfferCard.style';

import { Image } from '@/components/Image';

export interface OfferCardProps {
  id?: number;
  url: string;
  imageUrl: string;
  alt: string;
  title: string;
  subtitle: string;
  onClick?: () => void;
}

export const OfferCard = ({
  alt,
  imageUrl,
  url,
  title,
  subtitle,
  onClick
}: OfferCardProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <a className={classes.wrapper} href={url} onClick={onClick}>
      <div className={classes.shadowWrapper}>
        <Image
          alt={alt}
          className={classes.image}
          src={imageUrl}
          width={478}
          height={351}
        />
        <div className={classes.textWrapper}>
          <p className={classes.title}>{title}</p>
          <p className={classes.subtitle}>{subtitle}</p>
        </div>
      </div>
    </a>
  );
};
