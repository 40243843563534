import { useStyles } from './ProductCard.style';

import { Image } from '@/components/Image';
import { Label } from '@/components/Label';
import { Rating, type RatingProps } from '@/components/Rating';
import { Roundel } from '@/components/Roundel';
import { ProductCardBanner, type ProductCardBannerProps } from '@/features/ProductCard/ProductCardBanner';
import { ProductCardPrice, type ProductCardPriceProps } from '@/features/ProductCard/ProductCardPrice';
import { WishlistButton } from '@/features/WishlistButton';
import { productListClickTracking } from '@/lib/tracking/product';

export interface ProductCardProps {
  dealId: number;
  brand: string;
  url: string;
  imageUrl: string;
  labelContent: string;
  name: string;
  isSoldOut?: boolean;
  bannerProps?: ProductCardBannerProps;
  priceProps?: ProductCardPriceProps;
  ratingProps?: RatingProps;
  points?: string;
  listName?: string;
  offerId?: string;
}

export const ProductCard = ({
  dealId,
  brand,
  url,
  imageUrl,
  labelContent,
  name,
  isSoldOut,
  bannerProps,
  priceProps,
  ratingProps,
  points,
  listName,
  offerId
}: ProductCardProps): React.ReactElement => {
  const { classes } = useStyles();

  if (priceProps && bannerProps?.bannerType === 'price-drop') {
    priceProps.isPriceDrop = true;
  }

  if (priceProps && bannerProps?.bannerType === 'sale') {
    priceProps.isOnSale = true;
  }

  if (ratingProps) {
    ratingProps.dealUrl = url;
  }

  const handleClick = (): void => {
    productListClickTracking(dealId, name, listName, priceProps?.currentPrice, brand, offerId);
  };

  return (
    <div className={classes.card}>
      <a className={classes.cardLink} href={url} onClick={handleClick}>
        <div className={classes.imageContainer}>
          <Image
            alt={name}
            className={classes.image}
            width={200}
            height={200}
            src={imageUrl}
            fit="bounds"
            queryParams={{ imgclass: 'deallistingthumbnail' }}
          />
          {points && (
            <div className={classes.roundel}>
              <Roundel points={points} />
            </div>
          )}
        </div>
        {bannerProps && (
          <div className={classes.banner}>
            <ProductCardBanner {...bannerProps} />
          </div>
        )}
        <div className={classes.labelSection}>
          {(labelContent || isSoldOut) && (
            <Label isSecondary={isSoldOut}>
              {isSoldOut ? 'Sold Out' : labelContent}
            </Label>
          )}
        </div>
        <p className={classes.title}>{name}</p>
        {(priceProps && !isSoldOut) && (
          <ProductCardPrice {...priceProps} />
        )}
      </a>
      <div className={classes.bottomSection}>
        <WishlistButton dealId={dealId} dealName={name} />
        {ratingProps && (
          <Rating {...ratingProps} />
        )}
      </div>
    </div>
  );
};
