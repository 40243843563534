import { useStyles } from './Button.style';

interface ButtonProps {
  children?: React.ReactNode;
  url?: string;
  className?: string;
}

export const Button = ({ children, url, className }: ButtonProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <a className={cx(classes.btnDefault, className)} href={url || ''} >
      {children}
    </a>
  );
};
