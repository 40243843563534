import { useStyles } from './SimpleBanner.style';

import { Image } from '@/components/Image';

export interface SimpleBannerProps {
  url: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  alt: string;
  title?: string;
}

export const SimpleBanner = ({ url, desktopImageUrl, mobileImageUrl, alt, title }: SimpleBannerProps): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <a href={url} className={classes.bannerWrapper}>
      {title && (
        <h1 className={classes.title}>{title}</h1>
      )}
      <Image
        alt={alt}
        className={classes.mobileImage}
        src={mobileImageUrl}
        width={394}
        height={80}
        fit="cover"
      />
      <Image
        alt={alt}
        className={classes.desktopImage}
        src={desktopImageUrl}
        width={1366}
        height={150}
        fit="cover"
      />
    </a>
  );
};
