import { type ProductCardSliderProps } from '@/features/ProductCardSlider';
import { pushToGtmDatalayer } from '@/lib/utils';

export const productListViewTracking = (productCardSliderProps: ProductCardSliderProps): void => {
  const impressions = productCardSliderProps.products?.map((product, index) => ({
    name: product.name,
    id: product.dealId,
    brand: product.brand,
    position: index,
    EDR_OfferID: product.offerId,
    list: productCardSliderProps.title || ''
  })) || [];

  pushToGtmDatalayer({
    event: 'custom.product.list.view',
    group: productCardSliderProps.title || '',
    info: {
      count: productCardSliderProps.products?.length || 0
    },
    ecommerceType: 'impressions',
    ecommerce: {
      currencyCode: 'aud',
      impressions
    }
  });
};

export const productListClickTracking = (
  id: number,
  name: string,
  listName?: string,
  price?: number,
  brand?: string,
  EDR_OfferID?: string
): void => {
  pushToGtmDatalayer({
    event: 'custom.product.list.click',
    ecommerce: {
      item_list_id: '',
      item_list_name: listName,
      items: [{
        id: id.toString(),
        name,
        brand,
        EDR_OfferID,
        item_list_id: '',
        item_list_name: listName,
        price
      }]
    }
  });
};
