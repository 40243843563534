import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  btnDefault: {
    padding: '0.81rem 2.5rem',
    backgroundColor: theme.colors.orange[5],
    color: '#fff',
    border: 'none',
    borderRadius: '2.5rem',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    textAlign: 'center',
    display: 'block',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#dd4620'
    }
  }
}));
